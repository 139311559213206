<template>
  <div>
    <div class="flix-text-right" style="padding: 20px">
      <nextAppointments />
    </div>
  </div>
</template>

<script>
export default {
  props: { date: Date, callback: Function },
  components: {
    nextAppointments() {
      return import('./next')
    }
  },
  data() {
    return {
      searchField: false
    }
  },
  methods: {
    changeDate(add) {
      if (!add) {
        return this.callback(new Date())
      }
      var date = new Date(this.date.valueOf())
      date.setDate(date.getDate() + add)
      this.callback(date)
    }
  }
}
</script>

<style scoped lang="sass">

.flix-html-h1
  margin-top: 0
  margin-bottom: 0
</style>
